import { useParams } from 'react-router-dom';
import { DocumentProvider } from '../../context/DocumentProvider';
import DocumentViewer from '../../components/DocumentViewer';
import styles from './styles.module.scss';
import { Toaster } from 'react-hot-toast';

export const DocumentPage = () => {
  const { envelopeId } = useParams();

  return (
    <DocumentProvider>
      <Toaster />
      <div className={styles.container}>
        <div className={styles.documentContainer}>
          <DocumentViewer envelopeId={envelopeId ?? ''} />
        </div>
      </div>
    </DocumentProvider>
  );
};
